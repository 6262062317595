import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '../../../services/config.service';
import moment from 'moment-timezone';
import {DateFormatterService} from '../../../services/dateformat';
import {CONSTANT} from '../../../config/constant';
import {forEach, merge, orderBy} from 'lodash';
import {NamingConventionFilterPipe} from '../../../pipes/namingConvention.pipe';
import {IOrderAsset} from "../../../state/Assets/interfaces/IAsset.order.modal";
import {LazyLoadEvent} from "primeng/api";
import { LangUtilService } from '../../../app/util/lang-util.service';
import { Ability } from '@casl/ability';
import { Actions ,Subject} from '../../../../src/auth/rules';

const MMD_MODULE_NAME = CONSTANT.DMS_MODULES.MMD.MODULE;

@Injectable()
export class O2dAssignmentService {
  constructor(private http: HttpClient, public configService: ConfigService,

    private langUtilService:LangUtilService,
    public dateFormatter: DateFormatterService,
    private ability:Ability) {
  }

  ACTIONS=Actions
  SUBJECT=Subject
  // getAllAssignments(
  //   skip: string,
  //   limit: string,
  //   startDate?: string,
  //   endDate?: string,
  //   deliveryStatus?: string,
  //   searchedText?: string
  // ) {
  //   let queryUrl = `${this.configService.appConfig.appBaseUrl}deliveryManagement/management?skip=${skip}&limit=${limit}`;
  //   if (startDate) {
  //     queryUrl = queryUrl + '&startDate=' + startDate;
  //   }
  //   if (endDate) {
  //     queryUrl = queryUrl + '&endDate=' + endDate;
  //   }
  //   if (deliveryStatus) {
  //     queryUrl = queryUrl + '&deliveryStatus=' + deliveryStatus;
  //   }
  //   if (searchedText) {
  //     queryUrl = queryUrl + '&search=' + searchedText;
  //   }
  //   return this.http.get(queryUrl);
  // }

  cancelLpAssignment(id) {
    return this.http.delete(this.configService.appConfig.appBaseUrl + 'orderToDelivery/order/cancelLpAssignment/' + id);
  }

  // getAllTimeSlots() {
  //   const queryUrl = `${this.configService.appConfig.appBaseUrl}orderToDelivery/order/settings/order?reqFields=assignmentWindow`;
  //   return this.http.get<IAssignmentWindowForO2DWithWindow>(queryUrl);
  // }

  buildFullTimelineContentArray(rowData) {
    const timelineContentArrayCopy = [];
    const modifyTimeLine = [];
    if (rowData.timeLine && rowData.timeLine.length > 0) {
      rowData.timeLine = rowData.timeLine.filter(d => d !== null);
      forEach(rowData.timeLine, (value) => {
        const deliveryStatus =
          value.deliveryStatusObj && value.deliveryStatusObj.date
            ? value.deliveryStatusObj
            : value.deliveryStatus && value.deliveryStatus.date
              ? value.deliveryStatus
              : null;
        const eventStatus =
          value.event && value.event.date
            ? value.event
            : value.event && value.event.date
              ? value.event
              : null;
        let timeLineData = {};
        if (deliveryStatus || eventStatus) {
          if (deliveryStatus) {
            timeLineData = merge(timeLineData, deliveryStatus);
            timeLineData['status'] = deliveryStatus.deliveryStatus;
          } else if (eventStatus) {
            timeLineData = merge(timeLineData, eventStatus);
            timeLineData['deliveryStatus'] = eventStatus.status;
          }
          if (value.agent && value.agent.name) {
            timeLineData['agentName'] = value.agent.name;
            if (value.agent.mobileNumber) {
              timeLineData['agentName'] = `${timeLineData['agentName']} (Mobile : ${value.agent.mobileNumber})`;
            } else if (value.agent.mobileNo) {
              timeLineData['agentName'] = `${timeLineData['agentName']} (Mobile : ${value.agent.mobileNo})`;
            }
          }
          if (value.agent) {
            value.agent.otp
              ? timeLineData['deliveryOtp'] = (value.agent.otp).toString()
              : value.agent.deliveryOtp
                ? timeLineData['deliveryOtp'] = (value.agent.deliveryOtp).toString()
                : '';
            /* if (value.agent.otp) {
              timeLineData["deliveryOtp"] = (value.agent.otp).toString();
            } else if (value.agent.deliveryOtp) {
                timeLineData["deliveryOtp"] = (value.agent.deliveryOtp).toString();
            } */
          }
          if (value.logisticProvider && value.logisticProvider.name) {
            timeLineData['logisticProvider'] = value.logisticProvider.name;
          }
          if (value.deliveryStatusObj && value.deliveryStatusObj['location']) {
            timeLineData['location'] = value.deliveryStatusObj['location'];
          }

          modifyTimeLine.push(timeLineData);
        }
      });
      let timeLine = [];
      timeLine = orderBy(modifyTimeLine, ['date'], ['desc']);
      forEach(timeLine, (value) => {
        const header = value.status;
        const filterPipe = new NamingConventionFilterPipe();
        const fiteredString = filterPipe.transform(header ? header : '');
        let content = '';
        const contents: any = [];
        const displayData: any = [];
        let description = '';
        let reason = '';
        let location: any = null;
        if (value['agentName']) {
          let label = 'Agent : ';
          if (rowData.moduleType && rowData.moduleType === MMD_MODULE_NAME) {
            label = 'Carrier : ';
          }
          content = label + value['agentName'];
          contents.push(content);
        }
        if (value['logisticProvider']) {
          content = 'Lp : ' + value['logisticProvider'];
          contents.push(content);
        }
        if (value['boxTemp'] && value['boxTemp'] !== 'NA') {
          let label = 'Box Temp : ';
          if (rowData.moduleType && rowData.moduleType === MMD_MODULE_NAME) {
            label = 'Temp : ';
          }
          content = label + parseFloat(value['boxTemp'].toFixed(2)) + ' °C';
          contents.push(content);
        }
        /* if (value["reason"]) {
          content = "Reason : " + value["reason"];
          contents.push(content);
        } */
        if (value['deliveryOtp']) {
          content = 'Delivery OTP : ' + value['deliveryOtp'];
          contents.push(content);
        }
        if (value['description']) {
          description = value['description'];
        }
        if (value['reason']) {
          reason = value['reason'];
        }

        if (value.location) {
          location = value.location;
        }
        if (value?.displayData) {
          displayData.push(value.displayData[0]);
        }

        timelineContentArrayCopy.push(
          this.buildTimelineObject(
            value.imageId,
            '',
            fiteredString,
            contents,
            moment(value.date).format('MMMM Do YYYY, hh:mm:ss a'),
            null,
            description,
            reason,
            location,
            displayData
          )
        );
      });
    }
    // setTimeout(function () {
    //   _this._timelineTab.timelineContentArray = timelineContentArrayCopy;
    // }, 100);
    return timelineContentArrayCopy;
  }

  buildTimelineObject(
    imageId: string,
    iconToUse: string,
    header: string,
    contents: any[],
    timestamp?: string,
    _iconType?: null,
    description?: string,
    reason?: string,
    location?: {},
    displayData?: any[]
  ) {
    const obj = {
      icon: '',
      header: '',
      contents: [],
      iconType: '',
      image: '',
      timestamp: '',
      eventDescription: '',
      eventReason: '',
      location: {},
      displayData: []
    };
    obj.icon = iconToUse;
    obj.header = header;
    obj.contents = contents;
    obj.image = imageId;
    obj.timestamp = timestamp ? timestamp : null;
    obj.eventDescription = description ? description : null;
    obj.eventReason = reason ? reason : null;
    obj.location = location ? location : null;
    obj.displayData = displayData ? displayData : null;
    return obj;
  }

  filterCheck(lazyLoadEvent: LazyLoadEvent, columnFieldName: string) {
    return lazyLoadEvent &&
      lazyLoadEvent.filters &&
      lazyLoadEvent.filters[columnFieldName] &&
      lazyLoadEvent.filters[columnFieldName]?.['length'] > 0 &&
      lazyLoadEvent.filters[columnFieldName][0].value
  }

  getFilterValue(columnFieldName: string, lazyLoadEvent: LazyLoadEvent) {
    if (this.filterCheck(lazyLoadEvent, columnFieldName)) {
      const values = lazyLoadEvent.filters[columnFieldName][0].value;
      const locArray = [];
      if (Array.isArray(values)) {
        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          if (value['filterParameterValue']) {
            locArray.push(value['filterParameterValue']);
          }
        }
        return locArray.toString();
      } else {
        return lazyLoadEvent.filters[columnFieldName][0]?.value['filterParameterValue'];
      }
    } else {
      return null;
    }
  }

  getCustomerDetails(data: IOrderAsset) {
    // const translate = new TranslatePipe(this.translate,this.cd);
    data['custDetails'] = [];
    data['deliveryCenterDetails'] = [];
    if (data.assetData && data.assetData.customerDetails) {
      if (
        data.assetData.customerDetails.firstName &&
        data.assetData.customerDetails.lastName
      ) {
        data['firstName'] = `${data.assetData.customerDetails.firstName} ${data.assetData.customerDetails.lastName}`;
        data.assetData['firstName'] = `${data.assetData.customerDetails.firstName} ${data.assetData.customerDetails.lastName}`;
        data['custDetails'].push(data['firstName']);
        data['deliveryCenterDetails'].push(data['firstName']);
      } else if (data.assetData.customerDetails.firstName) {
        data['firstName'] = data.assetData.customerDetails.firstName;
        data.assetData['firstName'] = data.assetData.customerDetails.firstName;
        data['custDetails'].push(data['firstName']);
        data['deliveryCenterDetails'].push(data['firstName']);
      }

      if (data.assetData.customerDetails.location) {
        let cityWithPincode = '';
        if (data.assetData.customerDetails.location.city) {
          data['city'] = data.assetData.customerDetails.location.city;
          data.assetData['city'] = data.assetData.customerDetails.location.city;
          cityWithPincode = data.assetData.customerDetails.location.city;
        }
        if (data.assetData.customerDetails.location.pincode) {
          data['pincode'] = data.assetData.customerDetails.location.pincode;
          data.assetData['pincode'] =
            data.assetData.customerDetails.location.pincode;
          cityWithPincode = `${cityWithPincode} - ${data.assetData.customerDetails.location.pincode}`;
        }
        data['custDetails'].push(cityWithPincode);
        data['deliveryCenterDetails'].push(cityWithPincode);
      }

      const mobileNumbers = Array.isArray(data?.assetData?.customerDetails?.mobileNumber) ? data.assetData.customerDetails.mobileNumber :
                            data?.assetData?.customerDetails?.mobileNumber ? [data.assetData.customerDetails.mobileNumber] : []
      if (mobileNumbers?.length) {
        data['mobileNumber'] = mobileNumbers;
        data.assetData['customerMobile'] = mobileNumbers;
        let lang = this.langUtilService.getTranslation('sys.shipping_number')
        const shippingPh = `${lang}:${data['mobileNumber'][0]}`;
        data['custDetails'].push(shippingPh);
        data['deliveryCenterDetails'].push(shippingPh);
        mobileNumbers.forEach((mobile, idx) => idx && data['custDetails'].push(mobile) && data['deliveryCenterDetails'].push(mobile))
      }
      if (data.billAddress && data.billAddress.phone) {
        let lang = this.langUtilService.getTranslation('sys.billing_number')
        const billingPh = `${lang}:${data.billAddress.phone}`;
        data['custDetails'].push(billingPh);
        data['deliveryCenterDetails'].push(billingPh);
      }
    }
    return data;
  }

  getItemsList(data: IOrderAsset) {
    if (
      data.orderDetails &&
      data.orderDetails.items &&
      data.orderDetails.items.length > 0
    ) {
      let item: string;
      item = '';
      const itemList = [];
      forEach(data.orderDetails.items, function (value) {
        item = value['name'] + ' - Qty: ' + value['quantity'];
        itemList.push(item);
      });
      data['items'] = itemList;
    }
    return data;
  }

  getOrderDate(data) {
    if (data && data.pickup && data.pickup.created) {
      data['expectedDeliveryDateTimeDetails'] = this.dateFormatter.convertDateToSelectedTimezone(data.pickup.created, 'DD/MM/YY');
      data.assetData['expectedDeliveryDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.pickup.created, 'DD/MM/YY');
    }
    return data;
  }

  getExpectedDeliveryDateTime(data: IOrderAsset) {
    if (data?.assetData?.expectedDeliveryDateTime) {
      if (data.assetData.expectedDeliveryDateTime?.dateTime) {
        data['expectedDeliveryDateTimeDetails'] =
          this.dateFormatter.convertDateToSelectedTimezone(
            data.assetData.expectedDeliveryDateTime.dateTime,
            'DD/MM/YY'
          );

        data.assetData['expectedDeliveryDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.assetData.expectedDeliveryDateTime.dateTime, 'DD/MM/YY');
      }
      if (data.assetData.expectedDeliveryDateTime?.time) {
        data['expectedDeliveryDateTimeDetails'] = `${data['expectedDeliveryDateTimeDetails']}, ${this.dateFormatter.getFormattedTimeSlot(data.assetData.expectedDeliveryDateTime.time)}`;
        data.assetData['expectedDeliveryTime'] = data.assetData.expectedDeliveryDateTime.time;
        data['deliveryDate'] = data['deliveryDate'] + ', ' + this.dateFormatter.getFormattedTimeSlot(data.assetData.expectedDeliveryDateTime.time);
        data.assetData['expectedDeliveryTime'] = data.assetData.expectedDeliveryDateTime.time;
        data['deliverySlot'] = data.assetData.expectedDeliveryDateTime.time;
      }
    }
    return data;
  }

  getCreatedDate(data: IOrderAsset) {
    const isValid = this.isValidISODate(data?.created)
    if (data?.created && isValid) {
      let cDate = this.dateFormatter.convertDateToSelectedTimezone(data.created, 'DD/MM/YY');
      cDate = cDate + ',' + ' ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(data.created, 'LTS');
      data['created'] = cDate;
    }
    return data;
  }

  isValidISODate(dateString: string): boolean {
    // Regular expression to match ISO 8601 format
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/;
    return iso8601Regex.test(dateString);
  }

  getPickupDetails(data: IOrderAsset) {
    if (data.pickUpLocation && data.pickUpLocation.name) {
      if (data.pickUpLocation.city) {
        data['pickupLocation'] =
          data.pickUpLocation.name + ', ' + data.pickUpLocation.city;
      } else {
        data['pickupLocation'] = data.pickUpLocation.name;
      }
    }
    if (data.pickUpCity && data.pickUpCity.city) {
      data['pickupCity'] = data.pickUpCity.city;
    }
    return data;
  }

  getDeliveryStatusDate(data: IOrderAsset) {
    data['orderStatusDetails'] = [];
    if (data.orderStatus) {
      data['orderStatusDetails'].push(data.orderStatus);
    } else if (data.pickup && data.pickup.status) {
      data['orderStatusDetails'].push(data.pickup.status);
    }

    if(data?.pickup?.statusDate)
      data['orderStatusDetailsSubtitle'] = this.dateFormatter.convertDateToSelectedTimezone(data.pickup.statusDate, 'DD/MM/YY') + ', ' + this.dateFormatter.getFormattedTimeSlotForSingleTime(data.pickup.statusDate, 'LTS');
    if (data.delivery && data.delivery.deliveryStatus) {
      const field = data.delivery.deliveryStatus + '_dateTime';
      let statusDate = '';
      let statusFormatedDate = '';
      if (data.delivery[field]) {
        statusDate = this.dateFormatter.convertDateToSelectedTimezone(
          data.delivery[field], 'DD/MM/YY'
        );
        statusDate = statusDate + ',' + ' ';
        //  data["statusDate"] = statusDate + this.dateFormatter.getFormattedTimeSlotForSingleTime(data.delivery[field]);
        statusFormatedDate = statusDate + this.dateFormatter.getFormattedTimeSlotForSingleTime(data.delivery[field]);
        data['orderStatusDetails'].push(statusFormatedDate);
      } else if (data.delivery.updated) {
        statusDate = this.dateFormatter.convertDateToSelectedTimezone(
          data.delivery.updated, 'DD/MM/YY'
        );
        statusDate = statusDate + ',' + ' ';
        //  data["statusDate"] = statusDate + this.dateFormatter.getFormattedTimeSlotForSingleTime(data.delivery.updated);
        statusFormatedDate = statusDate + this.dateFormatter.getFormattedTimeSlotForSingleTime(data.delivery.updated);
        data['orderStatusDetails'].push(statusFormatedDate);
      }
    }
    return data;
  }

  getLogisticsDetails(data: IOrderAsset, logisticProviderList) {
    const selectedLp = logisticProviderList?.find(lp => lp?._id === data?.lpDetails?._id)
    if (selectedLp) {
      data['logistic'] = selectedLp?.name
    } else if (data?.logisticProvider?.name) {
      data['logistic'] = data.logisticProvider.name;
    } else if (data?.lpDetails?.name) {
      data['logistic'] = data.lpDetails.name;
    }
    return data;
  }

  getOtherDetails(data: IOrderAsset) {
    if (data.assetData && data.assetData.orderId) {
      data['orderId'] = data.assetData.orderId;
    }
    if (data.billAddress && data.billAddress.phone) {
      data['billingMobile'] = data.billAddress.phone;
    }
    if (data.lpDetails && data.lpDetails.id) {
      data['lpTrackingId'] = data.lpDetails.id
    }
    if (data?.tableData?.tableNo) {
      data['tableNo'] = data.tableData.tableNo
    }
    if(data?.pickUpLocation?.branchCode){
      data['deliveryCode'] = data?.pickUpLocation?.branchCode
    }
    if(data?.customerCollabrationStatus?.currentStatus){
      data['customerCollabrationStatus'] = data.customerCollabrationStatus.currentStatus
    }
    if(data?.additionalFields?.invoiceNumber){
      data['invoiceNumber'] = data?.invoiceNumber ?? data.additionalFields.invoiceNumber
    }
    if(data?.additionalFields?.invoiceDate){
      data['invoiceDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.additionalFields.invoiceDate, 'DD/MM/YY')
    }
    if(data?.shipmentDate){
      const isValid = this.isValidISODate(data?.shipmentDate)
      if (isValid) {
        data['shipmentDate'] = this.dateFormatter.convertDateToSelectedTimezone(data.shipmentDate, 'DD/MM/YY')
      }
    }
    return data;
  }

  buildOrderedItemsForExport(data: IOrderAsset) {
    let items = '';
    if (
      data.orderDetails &&
      data.orderDetails.items &&
      data.orderDetails.items.length > 0
    ) {
      items = '';
      for (let i = 0; i <= data.orderDetails.items.length - 1; i++) {
        if (i === 0) {
          items =
            `${items +
            data.orderDetails.items[i]['name']} - Qty: ${data.orderDetails.items[i]['quantity']}`;
        } else {
          items =
            `${items},
${data.orderDetails.items[i]['name']} - Qty: ${data.orderDetails.items[i]['quantity']}`;
        }
      }
    }
    return items;
  }

  setStatusDateForExport(data: IOrderAsset) {
    let statusDate = 'NA';
    if (data.delivery && data.delivery.deliveryStatus) {
      const field = data.delivery.deliveryStatus + '_dateTime';
      if (data.delivery[field]) {
        statusDate = this.dateFormatter.convertDateToSelectedTimezone(data.delivery[field], 'DD/MM/YY');
        statusDate = `${statusDate}, ${this.dateFormatter.getFormattedTimeSlotForSingleTime(data.delivery[field])}`;
      } else if (data.delivery.updated) {
        statusDate = this.dateFormatter.convertDateToSelectedTimezone(data.delivery.updated, 'DD/MM/YY');
        statusDate = `${statusDate}, ${this.dateFormatter.getFormattedTimeSlotForSingleTime(data.delivery.updated)}`;
      }
    }
    return statusDate;
  }

  buildExpectedDeliveryDateTimeForExport(data) {
    let expectedDeliveryTime: any = '';
    if (data.expectedDeliveryDateTime.dateTime) {
      expectedDeliveryTime = this.dateFormatter.convertDateToSelectedTimezone(data.expectedDeliveryDateTime.dateTime, 'DD/MM/YY');
    }
    if (data.expectedDeliveryDateTime.time) {
      expectedDeliveryTime = `${expectedDeliveryTime}, ${this.dateFormatter.getFormattedTimeSlot(data.expectedDeliveryDateTime.time)}`;
    }
    return expectedDeliveryTime;
  }

  buidlCustNameForExport(data) {
    let cDetails: any = null;
    if (data && data.customerDetails) {
      cDetails = data.customerDetails;
    }
    let fName = '';
    let lName = '';
    if (cDetails) {
      if (cDetails['firstName']) {
        fName = cDetails['firstName'];
      }
      if (cDetails['lastName']) {
        lName = cDetails['lastName'];
      }
      return `${fName} ${lName}`;
    } else {
      return '-';
    }
  }

  buildExpectedDeliveryDateTimeFieldForApi(dateField){
    const expectedField = 'expectedDeliveryDateTimeDetails';
    const expectedFieldForApi = 'expectedDeliveryDateTime.dateTime'
    if(dateField === expectedField){
      return expectedFieldForApi;
    } else {
      return dateField
    }
  }

  getFilterDateValue(lazyLoadEvent: LazyLoadEvent, dateType?: string, dateRange?:string) {
    const createdField = 'created';
    const expectedField = 'expectedDeliveryDateTimeDetails';
    const expectedFieldForApi = 'expectedDeliveryDateTime.dateTime'
    if (this.filterCheck(lazyLoadEvent, createdField)) {
      const values = lazyLoadEvent.filters[createdField][0].value;
      if (Array.isArray(values) && values.length > 1) {
        if (dateType) {
          if (dateType === 'startDate') {
            return moment(values[0]).startOf('days');
          } else if (dateType === 'endDate') {
            return moment(values[1]).endOf('days');
          }
        } else {
          return createdField;
        }
      }
    } else if (this.filterCheck(lazyLoadEvent, expectedField)) {
      const values = lazyLoadEvent.filters[expectedField][0].value;
      if (Array.isArray(values) && values.length > 1) {
        if (dateType) {
          if (dateType === 'startDate') {
            return moment(values[0]).startOf('days');
          } else if (dateType === 'endDate') {
            return moment(values[1]).endOf('days');
          }
        } else {
          return expectedFieldForApi;
        }
      } else if(dateRange === 'week'){
        if (dateType === "startDate") {
          return moment().subtract(6, 'days').startOf('days');
        } else if (dateType === "endDate") {
          return moment().endOf("days");
        }
      }
    } else if(dateRange === 'week'){
      if (dateType === "startDate") {
        return moment().subtract(6, 'days').startOf('days');
      } else if (dateType === "endDate") {
        return moment().endOf("days");
      }
    } else {
      if (dateType === 'startDate') {
        return moment().startOf('days');
      } else if (dateType === 'endDate') {
        return moment().endOf('days');
      }
    }
  }

  getActionMethods(deliveryStatus:string,logisticProvider,statuses){
    const actions = [];

    const {showQr,printQr,orderFulfil,showDocuments} = statuses || {};

    showQr && actions.push("View QR Code");
    printQr && actions.push("Print QR Code");

    if (orderFulfil) {
      if (deliveryStatus && deliveryStatus !== "delivered" && deliveryStatus !== "deliveryAcknowledged") {
        deliveryStatus !== "lpCancelled" && actions.push("Fulfill Order");
      } else if (!deliveryStatus && logisticProvider) {
        actions.push("Fulfill Order");
      }
    }      
    this.ability.can(this.ACTIONS.CUSTOMER_COLLABORATION, this.SUBJECT.O2D_MANAGEMENT) && actions.push("Customer Collaborations")
    showDocuments && actions.push("Documents")

    return actions;
  }

  getDateFilterValue(lazyLoadEvent: LazyLoadEvent) {
    const result = Object.keys(lazyLoadEvent?.filters).reduce((acc, key) => {
      const firstObject = lazyLoadEvent?.filters[key][0];
      if (firstObject?.matchMode === "dateRange" && firstObject?.isRemovalDate && firstObject?.value?.length > 1) {
        const startDate = moment(firstObject.value[0]).startOf('days');
        const endDate = moment(firstObject.value[1]).startOf('days')
        acc.push(`${key}:${startDate.valueOf()}:${endDate.valueOf()}`);
      }
      return acc;
    }, []).join(',');
    return result;
  }
}
