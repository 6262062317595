@if(type()==='week'){
<div class="days-list">
  @for (day of daysInWeek(); track day.name) {
  <div class="wd" [ngClass]="{'wd-selected' : day.selected}">
    {{day.label}}
  </div>
  }
</div>
}@else if(type()==='month'){
<div class="days-list">
  @for (day of daysInMonth(); track day.name) {
  <div class="md" [ngClass]="{'md-selected' : day.selected}">
    {{day.label}}
  </div>
  }
</div>
}