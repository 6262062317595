import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "../../services/config.service";
import { IOrderDocument } from "./interfaces/order.documents.interface";

@Injectable({
  providedIn: "root",
})
export class OrderDocumentsService {
  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) { }

  uploadDocuments(data, orderId: string) {
    const url = this.configService.appConfig.appBaseUrl + 'orderToDelivery/order/documents/orderDocId/' + orderId
    return this.http.post(url, data);
  }

  deleteOrderDocument(docId: string) {
    const url = this.configService.appConfig.appBaseUrl + 'orderToDelivery/order/documents/id/' + docId
    return this.http.delete(url);
  }

  getDocuments(orderId: string) {
    let url = this.configService.appConfig.appBaseUrl + `orderToDelivery/order/documents/all?orderDocId=${orderId}`
    return this.http.get(url);
  }

  extractDocumentsDataByCategory(documents:Array<IOrderDocument>) {
    const eData = {}
    const trackIndices = {}
    documents.forEach((doc) => {
      const fildDetails = { 
        type: doc.type,
        name : doc.fileName,
        size: doc.sizeInBytes,
        category:doc.category,
        fileUrl:doc.fileUrl,
        idx:trackIndices[doc.category] || 0,
        _id:doc._id
       }

      if (eData[doc.category]) {
        eData[doc.category].files.push(doc.fileUrl)
        eData[doc.category].data.push(fildDetails)
        eData[doc.category].count++
        trackIndices[doc.category]++

      } else {
        trackIndices[doc.category]=1
        eData[doc.category] = {
          files: [doc.fileUrl],
          data: [fildDetails],
          role: doc.category,
          count:1
        }
      }
    })
    return eData
  }

  getDocumentsPayload(selectedFilesForUpload){
    const data = []
    const formData = new FormData();

    for(const ct in selectedFilesForUpload){
      
     selectedFilesForUpload?.[ct]?.files?.forEach((file,idx:number)=>{

       if(typeof file!=='string')
       {
        formData.append('files', file);
        const {category,type} = selectedFilesForUpload[ct].data[idx]
        data.push({category,type})
       }

     })
    }

     formData.append('data', JSON.stringify(data));
     return data.length && formData
 
  }
}