import { CommonModule } from '@angular/common';
import { Component, effect, input, signal } from '@angular/core';

@Component({
  selector: 'app-days-view-in-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './days-view-in-table.component.html',
  styleUrl: './days-view-in-table.component.scss'
})
export class DaysViewInTableComponent {

  daysInMonth = signal<IDay[]>([]);
  selectedDays = input<number[]>([]);
  daysInWeek = signal<IDay[]>([
    { 'name': 'Sunday', 'selected': false, label: 'S' },
    { 'name': 'Monday', 'selected': false, label: 'M' },
    { 'name': 'Tuesday', 'selected': false, label: 'T' },
    { 'name': 'Wednesday', 'selected': false, label: 'W' },
    { 'name': 'Thursday', 'selected': false, label: 'T' },
    { 'name': 'Friday', 'selected': false, label: 'F' },
    { 'name': 'Saturday', 'selected': false, label: 'S' }
  ]
  );
  type = signal<string>('')

  constructor() {
    effect(() => {
      this.selectedDays() && this.setselectedDays()
    }, { allowSignalWrites: true });

  }
  setselectedDays() {
    const fD = Number(this.selectedDays()[0])
    if (Number.isNaN(fD)) {
      const daysInWeek = this.daysInWeek();

      this.type.set('week');
      this.selectedDays().forEach(day => {
        daysInWeek[daysInWeek.findIndex(({ name }) => day.toString() === name)].selected = true
      })
      this.daysInWeek.set(daysInWeek)

    } else {
      this.type.set('month');
      const data = []
      for (let day = 1; day <= 31; day++) {
        data.push({ 'name': day, 'selected': this.selectedDays().includes(day), label: day })
      }
      this.daysInMonth.set(data)
    }
  }
}

interface IDay {
  'name': string, 'selected': boolean, label: string
}
