import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { switchMap, from, catchError } from "rxjs";
import { MessageService } from "primeng/api";

import { OrderDocumentsService } from "./order.documents.service";
import { deleteOrderDocument, getOrderDocuments, getOrderDocumentsSuccess, uploadOrderDocuments, orderDocumentsActionSuccess } from "./order.documents.action";

@Injectable()
export class OrderDocumentsEffects {
  constructor(
    private actions$: Actions,
    private orderDocumentsService: OrderDocumentsService,
    private messageService: MessageService
  ) {}

  getOrderDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrderDocuments),
      switchMap((action) => {
        return from(
          this.orderDocumentsService.getDocuments(
            action.orderId
          )
        ).pipe(
          switchMap((res) => {
            return [
              getOrderDocumentsSuccess({
                documents:this.orderDocumentsService.extractDocumentsDataByCategory(res['data'] || [])
              }),
            ];
          }),
          catchError(() => {
            this.messageService.add({
              key: "global-notification",
              severity: "error",
              summary: "Error",
              detail: "Error Occured, Please Try Again...!",
            });
            return [];
          })
        );
      })
    )
  );

  uploadOrderDocuments$ = createEffect(() =>
  this.actions$.pipe(
    ofType(uploadOrderDocuments),
    switchMap((action) => {
      return from(
        this.orderDocumentsService.uploadDocuments(
          action.documents,
          action.orderId,
        )
      ).pipe(
        switchMap(() => {
          this.messageService.add({
            key: "global-notification",
            severity: "success",
            summary: "Success",
            detail: "Successfully Uploaded...!",
          });
          return [orderDocumentsActionSuccess({statutsKey:'upload_success'+Math.random()})];
        }),
        catchError(() => {
          this.messageService.add({
            key: "global-notification",
            severity: "error",
            summary: "Error",
            detail: "Error Occured, Please Try Again...!",
          });
          return [orderDocumentsActionSuccess({statutsKey:'upload_failure'+Math.random()})];
        })
      );
    })
  )
);

deleteOrderDocument$ = createEffect(() =>
  this.actions$.pipe(
    ofType(deleteOrderDocument),
    switchMap((action) => {
      return from(
        this.orderDocumentsService.deleteOrderDocument(
          action.docId
        )
      ).pipe(
        switchMap(() => {
          this.messageService.add({
            key: "global-notification",
            severity: "success",
            summary: "Success",
            detail: "Successfully Deleted...!",
          });
          return [orderDocumentsActionSuccess({statutsKey:'delete_success'+Math.random()})];
        }),
        catchError(() => {
          this.messageService.add({
            key: "global-notification",
            severity: "error",
            summary: "Error",
            detail: "Error Occured, Please Try Again...!",
          });
          return [orderDocumentsActionSuccess({statutsKey:'delete_failure'+Math.random()})];
        })
      );
    })
  )
);

}
