import { createAction, props } from "@ngrx/store";
import {IOrderDocument} from '../Order Documents/interfaces/order.documents.interface'

export const uploadOrderDocuments = createAction(
    '[Order Documents] upload documents',
    props<{orderId:string,documents:any}>()
)

export const orderDocumentsActionSuccess = createAction(
    '[Order Documents] upload documents Success',
    props<{statutsKey:string}>()
)

export const getOrderDocuments = createAction(
    '[Order Documents] get documents',
    props<{orderId:string}>()
)

export const deleteOrderDocument = createAction(
    '[Order Documents] delete document',
    props<{docId:string}>()
)

export const getOrderDocumentsSuccess = createAction(
    '[Order Documents] get documents Success',
    props<{documents:Record<string,IOrderDocument[]>}>()
)
